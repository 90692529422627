import React from "react";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { LinkText } from "../../templates/LinkText";

const LoadOptimization = () => {
  const { t } = useTranslation();
  return (
    <section className="services-details-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="services-details-desc">
              <h3>{t("Applications - Loadoptimization - Title - 1")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - Loadoptimization - Text - 1"
                  components={{
                    link_zev: <LinkText to="/zev" title="ZEV" />,
                  }}
                />
              </p>
              <p>
                <Trans
                  i18nKey="Applications - Loadoptimization - Text - 2"
                  components={{
                    link_sm: (
                      <LinkText
                        to="https://www.solarmanager.ch/"
                        title="Solar Manager"
                      />
                    ),
                  }}
                />
              </p>
              <p>{t("")}</p>
              <p>{t("")}</p>

              <h3>{t("Applications - Loadoptimization - Title - 2")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - Loadoptimization - Text - 3"
                  components={{
                    link_sm: (
                      <LinkText
                        to="https://www.solarmanager.ch/"
                        title="Solar Manager"
                      />
                    ),
                  }}
                />
              </p>
              <h3>{t("Applications - Loadoptimization - Title - 3")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - Loadoptimization - Text - 4"
                  components={{
                    link_sm: (
                      <LinkText
                        to="https://www.solarmanager.ch/"
                        title="Solar Manager"
                      />
                    ),
                  }}
                />
              </p>
              <p>{t("Applications - Loadoptimization - Text - 5")}</p>
              <h3>{t("Applications - Loadoptimization - Title - 4")}</h3>
              <p>
                <Trans
                  i18nKey="Applications - Loadoptimization - Text - 6"
                  components={{
                    link_sm: (
                      <LinkText
                        to="https://www.solarmanager.ch/"
                        title="Solar Manager"
                      />
                    ),
                  }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoadOptimization;
