import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { useTranslation } from "gatsby-plugin-react-i18next";
import LoadOptimization from "../components/Applications/LoadOptimization";
import { graphql } from "gatsby";
import { Seo } from "../components/App/SEO";

const LoadOptimizationPage = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle={t("Verbrauchsoptimierung")}
        homePageText="Home"
        homePageUrl="/"
        activePageText={t("Anwendungen")}
      />
      <LoadOptimization />
      {/*<RelatedServices />*/}
      <Footer />
    </Layout>
  );
};

export default LoadOptimizationPage;

export const query = graphql`
  query {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Solarstrom effizient mit Verbrauchsoptimierung nutzen"
    description="Der Eigenverbrauchsmanager dient zur regelungstechnischen Optimierung von Verbrauchern wie Wärmepumpen, Boiler, Elektromobil-Ladestationen und sogar Haushaltgeräten"
  />
);
